<template>
  <div>
    <el-dialog
      title="帖子Id"
      :visible="postIdMixShow"
      width="1000px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-title">
        <el-table :data="dataList">
            <el-table-column prop="name" label="广告名称"> </el-table-column>
            <el-table-column prop="post_id" label="帖子Id"> </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="close">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["postIdMixShow", "postIdMsgList"],
  data() {
    return {
      dataList: JSON.parse(JSON.stringify(this.postIdMsgList)),
    };
  },
  methods: {
    close() {
    //   this.backToInit(this.postIdMsgList);
      this.$emit("update:postIdMixShow", false);
    },
    openInit() {},
    sure() {
      if (this.dataList.some((v) => !v.title)) {
        this.$message({
          type: "warning",
          message: "行动号召不能为空",
        });
        return false;
      }
      this.$emit("setMixMsg", "title", this.dataList);
      this.close();
    },
  },
  watch: {
    postIdMsgList: {
      handler() {
        this.dataList = JSON.parse(JSON.stringify(this.postIdMsgList));
      },
    },
    // comVal: {
    //   handler(val) {
    //     this.batchChange("title", val);
    //   },
    // },
  },
};
</script>
<style lang="scss" scope>
.localsList {
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  .el-row {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.mix-wrap-title {
  .batch-p {
    display: flex;
    align-items: center;
    .emojiInputWrap {
      margin-left: 10px;
      width: 100%;
    }
  }
  .el-checkbox-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .el-checkbox {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mixName {
      width: 300px;
    }
    .emojiInputWrap {
      flex: 1;
    }
  }
}
</style>