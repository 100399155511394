<template>
  <div>
    <el-dialog
      :title="title"
      :visible="showUrlMixShow"
      width="1000px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-showurl">
        <p class="batch-p">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-input
            placeholder="批量修改显示链接"
            type="text"
            v-model="comVal"
            :disabled="!checkedDatas.length"
            @input="(val) => batchChange('show_url', val)"
          ></el-input>
        </p>
        <el-checkbox-group
          v-model="checkedDatas"
          @change="
            (val) => handleCheckedDatasChange('show_url', showUrlMsgList, val)
          "
        >
          <el-checkbox v-for="item in dataList" :label="item.id" :key="item.id">
            <span class="mixName">{{ item.name }}</span>
            <el-input
              placeholder="显示链接"
              type="text"
              v-model="item.show_url"
            ></el-input>
          </el-checkbox>
        </el-checkbox-group>
        <!-- <el-row :gutter="10" v-for="(item) in dataList" :key="item.id">
                    <el-col :span="6" style="line-height:40px">
                        <span class="mixName">{{item.name}}</span>
                    </el-col>
                    <el-col :span="18">
                         <el-input placeholder="推广链接" type="text" v-model="item.show_url"></el-input>
                    </el-col>
                </el-row> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import mixBatchOperate from "@/views/adManagement/assets/js/mixBatchOperate";
export default {
  mixins: [mixBatchOperate],
  props: {
    showUrlMixShow: {
      type: Boolean,
      default: () => false,
    },
    showUrlMsgList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => "链接",
    },
  },
  data() {
    return {
      dataList: JSON.parse(JSON.stringify(this.showUrlMsgList)),
    };
  },
  methods: {
    close() {
      this.backToInit(this.showUrlMsgList);
      this.$emit("update:showUrlMixShow", false);
    },
    openInit() {},
    sure() {
      this.$emit("setMixMsg", "showUrl", this.dataList);
      this.close();
    },
  },
  watch: {
    showUrlMsgList: {
      handler() {
        this.dataList = JSON.parse(JSON.stringify(this.showUrlMsgList));
      },
    },
    deep: true,
  },
};
</script>
<style lang="scss" scope>
.localsList {
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  .el-row {
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
.mix-wrap-showurl {
  .batch-p {
    display: flex;
    align-items: center;
    .el-input {
      margin-left: 10px;
      width: 85%;
    }
  }
  .el-checkbox-group {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .el-checkbox {
      width: 85%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
    .el-checkbox__label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .mixName {
      width: 300px;
    }
  }
}
</style>