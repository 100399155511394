<template>
    <div>
        <el-form :model="titleInfo" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="标题：" prop="page" class="pageSelect" require>
                <emoji-input palce="标题：广告标题" type="text" v-model="titleInfo.title"></emoji-input>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import emojiInput from '../../../createAd/components/emojiInput'
    export default {
        props:['titleMsg'],
        data () {
            return {
                titleInfo:JSON.parse(JSON.stringify(this.titleMsg))
            }
        },
        components:{
            emojiInput
        },
        methods: {
            setMsg(){
                this.$emit('getTitleMsg',this.titleInfo)
            },
        },
        watch: {
            titleMsg:{
                handler(){
                    this.titleInfo = {...this.titleMsg}
                },
                deep:true
            },
            titleInfo:{
                handler(){
                    this.setMsg()
                },
                deep:true
            }
        }
    }
</script>
<style lang="scss">
    
</style>