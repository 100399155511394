<template>
    <div>
        <el-form ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="帖子Id：" prop="page" class="pageSelect" require>
                <span>{{postIdInfo.post_id}}</span>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default {
        props:['postIdInfo'],
        data () {
            return {
                // postIdInfo:JSON.parse(JSON.stringify(this.postIdMsg))
            }
        },
        methods: {
            // setMsg(){
            //     this.$emit('getTitleMsg',this.titleInfo)
            // },
        },
        watch: {
            postIdMsg:{
                handler(){
                    // this.postIdInfo = {...this.postIdMsg}
                },
                deep:true
            },
        }
    }
</script>
<style lang="scss">
    
</style>