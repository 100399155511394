<template>
  <div>
    <el-dialog
      title="主页"
      :visible="pageMixShow"
      width="1000px"
      @close="close"
      @open="openInit"
      append-to-body
    >
      <div class="localsList mix-wrap-action">
        <p class="batch-p">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-select
              v-model="comValObj.page_id"
              placeholder="请选择主页"
               @change="changePages"
              filterable
            >
              <el-option
                :label="page.name"
                :value="page.id"
                style="height: 70px"
                v-for="page in pageList"
                :key="page.id"
                :disabled="!page.is_published"
              >
                <div class="pageItems">
                  <div class="pageImg">
                    <el-image :src="page.picture_url"></el-image>
                  </div>
                  <div class="pageMsg">
                    <p>
                      <el-tooltip
                        content="检测到提示主页已经被封"
                        v-if="page.isBlocked"
                        ><i class="el-icon-lock" style="color: #f00"></i
                      ></el-tooltip>
                      {{ page.name }}
                    </p>
                    <div>编号：{{ page.id }}</div>
                  </div>
                </div>
              </el-option>
            </el-select>
            <span style="margin-left: 20px">Instagram账号：</span>
            <el-select
              v-model="comValObj.instagram_actor_id"
              placeholder="请选择Instagram账号"
              @change="(val) => batchChange('instagram_actor_id', val)"
            >
              <el-option
                :label="instagrams.username"
                :value="instagrams.id"
                v-for="instagrams in pageInstagramsList"
                :key="instagrams.id"
                style="height: 70px"
              >
                <div class="pageItems">
                  <div class="pageImg">
                    <el-image :src="instagrams.profile_pic"></el-image>
                  </div>
                  <div class="pageMsg">
                    <p>{{ instagrams.username }}</p>
                    <div>编号：{{ instagrams.id }}</div>
                  </div>
                </div>
              </el-option>
            </el-select>
        </p>
        <el-checkbox-group
          v-model="checkedDatas"
          @change="
            (val) =>
              handleCheckedDatasChange(['page_id','instagram_actor_id'], pageMsgList, val)
          "
        >
          <el-checkbox v-for="item in dataList" :label="item.id" :key="item.id">
            <el-tooltip v-if="item.name" :content="item.name">
              <span class="mixName">{{ item.name }}</span></el-tooltip
            >
            <span style="margin-left:20px">主页：</span>
            <el-select
              v-model="item.page_id"
              placeholder="请选择主页"
              @change="selectPage(index, item.page_id)"
              filterable
            >
              <el-option
                :label="page.name"
                :value="page.id"
                style="height: 70px"
                v-for="page in pageList"
                :key="page.id"
                :disabled="!page.is_published"
              >
                <div class="pageItems">
                  <div class="pageImg">
                    <el-image :src="page.picture_url"></el-image>
                  </div>
                  <div class="pageMsg">
                    <p>
                      <el-tooltip
                        content="检测到提示主页已经被封"
                        v-if="page.isBlocked"
                        ><i class="el-icon-lock" style="color: #f00"></i
                      ></el-tooltip>
                      <!-- <el-tooltip :content="`当前主页在投放中/审核中广告数量：${page.adsRunningCount}`"><span :style="{'color':`${page.adsRunningCount>200?'#f00':'#67C23A'}`}">（{{'#'+page.adsRunningCount}}）</span></el-tooltip> -->
                      {{ page.name }}
                    </p>
                    <div>编号：{{ page.id }}</div>
                  </div>
                </div>
              </el-option>
            </el-select>
            <span style="margin-left: 20px">Instagram账号：</span>
            <el-select
              v-model="item.instagram_actor_id"
              placeholder="请选择Instagram账号"
            >
              <el-option
                :label="instagrams.username"
                :value="instagrams.id"
                v-for="instagrams in item.pageInstagramsList"
                :key="instagrams.id"
                style="height: 70px"
              >
                <div class="pageItems">
                  <div class="pageImg">
                    <el-image :src="instagrams.profile_pic"></el-image>
                  </div>
                  <div class="pageMsg">
                    <p>{{ instagrams.username }}</p>
                    <div>编号：{{ instagrams.id }}</div>
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-checkbox>
        </el-checkbox-group>
        <!-- <el-row :gutter="10" v-for="(item,index) in dataList" :key="item.id">
                    <el-col :span="6" style="line-height:40px">
                        <span class="mixName">{{item.name}}</span>
                    </el-col>
                    <el-col :span="18">
                         <el-select v-model="item.page_id" placeholder="请选择主页" @change="selectPage(index,item.page_id)" filterable>
                            <el-option :label="page.name" :value="page.id" style="height:70px" v-for="page in pageList" :key="page.id" :disabled="!page.is_published">
                                <div class="pageItems">
                                    <div class="pageImg">
                                        <el-image :src="page.picture_url"></el-image>
                                    </div>
                                    <div class="pageMsg">
                                        <p>
                                            <el-tooltip content="检测到提示主页已经被封" v-if="page.isBlocked"><i class="el-icon-lock" style="color:#f00"></i></el-tooltip>
                                            {{page.name}}
                                        </p>
                                        <div>编号：{{page.id}}</div>
                                    </div>
                                </div>
                            </el-option>
                        </el-select>
                        <span style="margin-left:20px">Instagram账号：</span>
                        <el-select v-model="item.instagram_actor_id" placeholder="请选择Instagram账号">
                            <el-option  :label="instagrams.username" :value="instagrams.id" v-for="instagrams in item.pageInstagramsList" :key="instagrams.id"  style="height:70px">
                                <div class="pageItems">
                                    <div class="pageImg">
                                        <el-image :src="instagrams.profile_pic"></el-image>
                                    </div>
                                    <div class="pageMsg">
                                        <p>{{instagrams.username}}</p>
                                        <div>编号：{{instagrams.id}}</div>
                                    </div>
                                </div>
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { pageInstagrams, pagePosts } from "@/api/creatAd";
import mixBatchOperate from "@/views/adManagement/assets/js/mixBatchOperate";
export default {
  props: ["pageMixShow", "pageMsgList", "pageList", "account", "targetUserId"],
  mixins: [mixBatchOperate],
  data() {
    return {
      initData: this.$store.getters.initData,
      list: [],
      dataList: JSON.parse(JSON.stringify(this.pageMsgList)),
      pageInstagramsList: [],
      msg:{}
    };
  },
  computed: {
    
  },
  methods: {
    close() {
      this.$emit("update:pageMixShow", false);
    },
    openInit() {},
    sure() {
      this.$emit("setMixMsg", "page", this.dataList);
      this.close();
    },
    changePages(v){
        pageInstagrams({ pageId: v, adAccountId: this.account },this.targetUserId
        ).then((res) => {
            this.pageInstagramsList = res.data;
            this.$set(this.comValObj,'instagram_actor_id',res.data[0].id)
            this.dataList.map((k,index)=>{
                if(this.checkedDatas.includes(k.id)){
                    this.$set(this.dataList[index], "pageInstagramsList", res.data);
                    this.dataList[index].instagram_actor_id = res.data[0].id;
                }
            })
        });
        this.batchChange('page_id', v);
    },
    selectPage(i, v) {
      pageInstagrams(
        { pageId: v, adAccountId: this.account },
        this.targetUserId
      ).then((res) => {
        this.dataList[i].instagram_actor_id = res.data[0].id;
        this.$set(this.dataList[i], "pageInstagramsList", res.data);
      });
    },
  },
  watch: {
    pageMsgList: {
      handler() {
        this.dataList = JSON.parse(JSON.stringify(this.pageMsgList));
        // this.dataList.map((v,i)=>{
        //     this.selectPage(i,v.page_id);
        // })
      },
    },
  },
};
</script>
<style lang="scss" scope>
.localsList {
  max-height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
  .el-row {
    margin-bottom: 10px;
  }
}
</style>