<template>
    <div>
        <el-form :model="pageMsg" ref="ruleForm" label-width="140px" class="demo-ruleForm">
            <el-form-item label="主页：" prop="page_id" class="pageSelect" require>
                <el-row>
                    <el-col :span="10">
                        <el-select v-model="pageMsg.page_id" placeholder="请选择主页" @change="selectPage" filterable clearable>
                            <el-option  v-for="page in pageList" :key="page.id" :label="page.name" :value="page.id" style="height:70px" :disabled="!page.is_published" >
                                <div class="pageItems">
                                    <div class="pageImg">
                                        <el-image :src="page.picture_url"></el-image>
                                    </div>
                                    <div class="pageMsg">
                                        <p>
                                            <el-tooltip content="检测到提示主页已经被封" v-if="page.isBlocked"><i class="el-icon-lock" style="color:#f00"></i></el-tooltip>
                                            <!-- <el-tooltip :content="`当前主页在投放中/审核中广告数量：${page.adsRunningCount}`"><span :style="{'color':`${page.adsRunningCount>200?'#f00':'#67C23A'}`}">（{{'#'+page.adsRunningCount}}）</span></el-tooltip> -->
                                            {{page.name}}
                                        </p>
                                        <div>编号：{{page.id}}</div>
                                    </div>
                                </div>
                                <set-page-status :page="page" @getList="getAcoountPages"></set-page-status>
                            </el-option>
                            
                        </el-select>
                        <el-tooltip class="item" effect="dark" content="强制刷新主页，清除本地缓存主页数据" placement="top">
                            <el-button icon="el-icon-refresh" @click="getAcoountPages(true)" type="text" size="mini" :disabled="loadPages"></el-button>
                        </el-tooltip>
                        <div style="font-size:12px;color:#999">总容量：{{numMsg.limit_on_ads_running_or_in_review}}，已使用：{{numMsg.ads_running_or_in_review_count}}</div>
                    </el-col>
                    <el-col :span="14">
                        <span v-if="objective !== 'PAGE_LIKES'">
                    <span style="margin-left:10px">Instagram账号：</span>
                    <el-select  :class="!pageInstagramsList.length?'instagram-sel':''" v-model="pageMsg.instagram_actor_id" placeholder="请选择Instagram账号" @change="setMsg" filterable clearable>
                        <el-option  :label="instagrams.username" :value="instagrams.id" v-for="instagrams in pageInstagramsList" :key="instagrams.id"  style="height:70px">
                            <div class="pageItems">
                                <div class="pageImg">
                                    <el-image :src="instagrams.profile_pic"></el-image>
                                </div>
                                <div class="pageMsg">
                                    <p>{{instagrams.username}}</p>
                                    <div>编号：{{instagrams.id}}</div>
                                </div>
                            </div>
                        </el-option>
                    </el-select>
                </span>
                    </el-col>
                </el-row>
                
                
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    import setPageStatus from '@/views/adManagement/createAd/components/setPageStatus.vue'  
    import {pageInstagrams,pagePosts,pageVolumes} from '@/api/creatAd'
    export default {
        props:['pageList','pageInfo','loadPages','objective','account','targetUserId'],
        data () {
            return {
                pageInstagramsList:[],
                postList:[],
                numMsg:{}
            }
        },
        components:{
            setPageStatus
        },
        computed:{
            pageMsg:{
                get(){
                    console.log(this.pageInfo);
                    if(!this.pageList.length){
                        return {
                            page_id:'',
                            instagram_actor_id:''
                        }
                    }
                    this.selectPage(this.pageInfo.page_id)
                    return JSON.parse(JSON.stringify(this.pageInfo));
                    
                },
                set(){
                    
                }
            },
        },
        methods: {
            selectPage(v){
                // console.log(this.adsList);
                if(!v) return;
                // this.pageMsg.page_id = v;
                this.getPageVolumes(v);
                // this.$set(this.adsList,this.activeIndex,this.adsList[this.activeIndex])
                this.$showLoading();
                pageInstagrams({pageId:v,adAccountId:this.account},this.targetUserId).then(res=>{
                    this.$hideLoading();
                    this.pageInstagramsList = res.data;
                    this.pageMsg.instagram_actor_id = res.data[0]?.id || '';
                    this.setMsg();
                })
                
                // pagePosts({pageId:v}).then(res=>{
                //     this.postList = res.data.list;
                // })
            },
            // 获取主页帖子容量
            getPageVolumes(v){
                let params = {
                    pageId:v,
                    accountId:this.account
                }
                pageVolumes(params,this.targetUserId).then(res=>{
                    this.numMsg = res.data;
                })
            },
            setMsg(){
                this.$emit('getPageMsg',this.pageMsg)
            },
            getAcoountPages(){
                this.$emit('getPages' ,true)
            },
        },
        watch: {
            
        }
    }
</script>
<style lang="scss">
    
</style>